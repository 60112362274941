
'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var throttle = require('lodash/function/throttle');

var isBackToTopBtnVisible = false;

var $backToTop = $("<div class='back-to-top'><i class='pbi-angle-down'></i> top</div>");

var dom = {
	document: $(document),
	menu: $('#menu')
};

var globals = window.mobile.globals;

var getPlatform = function() {

	return typeof window.mobile.globals.storePath === 'undefined' ? 'portfolio' : 'store';

};

var hideBackToTopBtn = function() {

	$('.back-to-top').remove();

	isBackToTopBtnVisible = false;

};

var addEventHandlers = function(){

	console.log(dom.document);
	console.log(dom.document.off);

	dom.document.off('.menu');

	dom.document.on('vclick.menu', '[data-is-dropdown="true"]', function(e){

		var $a = $(this),
			isExpanded = $a.attr('data-expanded') === 'true',
			caretClass = isExpanded ? 'icon-caret-right' : 'icon-sort-down';

		$a.parents('li').find('.subMenu').toggle();
		$a.find('i').attr('class', caretClass);

		$('#menuPanel').removeClass('ui-panel-fixed');

		$a.attr('data-expanded', !isExpanded);

		e.preventDefault();

	});

	dom.document.on('panelopen.menu', function(){
		$('.biz-menu-btn .icon-reorder').attr('class', 'icon-remove');
	});

	dom.document.on('click.menu', '.icon-shopping-cart', function(){
		window.location = window.mobile.globals.storePath + '?load=cart';
	});

	dom.document.on('panelclose.menu', function(){
		$('.biz-menu-btn .icon-remove').attr('class', 'icon-reorder');
	});

	dom.document.on('vclick.menu', '.topLink[data-is-dropdown=false][data-is-external=false]:not(#fullSiteLink),.subLink[data-is-external=false]', function(e){ // clicking an 'endpoint'
		$('.biz-menu-btn .icon-remove').attr('class', 'icon-reorder');
		$.mobile.changePage($(this).attr('href'));
		e.preventDefault();
	});

	dom.document.on('vclick.menu', '.topLink[target="_top"]:not(#fullSiteLink),.subLink[target="_top"]', function(){ // same page anchor

		var $el = $(this);

		if (getPlatform() === 'store') {

			$("#menuPanel").panel("close");

			$el.closest('ul').find('li').removeClass('selected');

			$el.closest('li').addClass('selected');

		}

	});

	dom.document.on('vclick.menu', 'a[data-is-external=true]', function(e){
		window.open($(this).attr('data-external-url'));
		e.preventDefault();
	});


	dom.document.on('touchmove', throttle(function() {

		if (getPlatform() === 'store') {

			if (window.scrollY > 1000) {

				if(!isBackToTopBtnVisible) {

					$('body').prepend($backToTop);

				}

				isBackToTopBtnVisible = true;

			}

			else {

				hideBackToTopBtn();

			}

		}

	}, 250));

	dom.document.on('click', '.back-to-top', function() {

		console.log('back-to-top!');

		window.scrollTo(0, 0);

		hideBackToTopBtn();

	});

};

var updateSelectedMenuItem = function(){

	var urlArr = window.location.href.split('/'),
		friendlyStr = urlArr[urlArr.length - 1],
		friendlyArr = friendlyStr.split('?'),
		friendlyName = friendlyArr[0],
		selector = '.friendly-' + friendlyName;

	if(friendlyName === ''){
		selector = '.page-' + globals.homePageId;
	}

	dom.menu.find('li').removeClass('selected');

	$(selector).parents('li').addClass('selected');

};

var init = function(){

	addEventHandlers();

};

module.exports = {
	init: init,
	updateSelectedMenuItem: updateSelectedMenuItem
};

