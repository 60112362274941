// Any modules that need to eventually be exposed to legacy js will be stored here.
var exports = {};

// Get custom vendor build modules.
require('mobile-core-modernizr');
require('mobile-core-jquery-ui');

// Get generic vendor modules.
require('jquery-validate');
require('jquery-cookie');

// Get generic custom modules.
require('image-protect');

// Get mobile specific custom js.
exports.mobile = require('mobile-core-mobile');
exports.slideshow = require('mobile-core-slideshow');

module.exports = exports;
